import React from 'react';
import Layout from '../../components/layout';
import { Typography, Box, List, ListItem, Link } from '@material-ui/core';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';

const PrivacyPolicyPage = (props, { children }) => {
  return (
    <Layout>
      <SectionContainerLayoutWithFilter title='PRIVACY POLICY'>
        <Typography variant='body1' color='textPrimary'>
          This privacy policy applies between you, the User of this Website, and Global Manufacturing Organisation
          Limited, the owner and provider of this Website (www.gmisummit.com). Global Manufacturing Organisation Limited
          takes the privacy of your information very seriously. This privacy policy applies to our use of any and all
          Data collected by us or provided by you in relation to your use of the Website. Please read this privacy
          policy carefully.
        </Typography>
        <Typography gutterBottom variant='h6' className='text-uppercase'>
          <Box my={3}>Overview and Interpretation</Box>
        </Typography>
        <ol>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                Global Manufacturing Organisation Limited is the controller of, and responsible for, your personal data.
              </Typography>
              <Typography variant='body2' color='textPrimary'>
                We keep our privacy under regular review. The version was last updated on May 20, 2018.
              </Typography>
            </Box>
          </li>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                In this privacy policy, the following definitions are used:
              </Typography>
              <Typography gutterBottom variant='body2' color='textPrimary'>
                <Box clone pr={1}>
                  <Typography variant='button' color='textPrimary'>
                    Data:
                  </Typography>
                </Box>
                Collectively all personal information that you submit to GMOL via the Website.
              </Typography>
              <Typography gutterBottom variant='body2' color='textPrimary'>
                <Box clone pr={1}>
                  <Typography variant='button' color='textPrimary'>
                    Global Manufacturing Organisation Limited, GMOLS Global Manufacturing & Industrialisation Summit,
                    GMIS, we or us:
                  </Typography>
                </Box>
                Global Manufacturing Organisation Limited, a company incorporated in the United Arab Emirates with
                registered number MC11712 whose registered office is at Office No. 135-FD, Level 1, Incubator Building,
                Masdar City, Abu Dhabi, United Arab Emirates (“GMOL”). You can contact GMOL by email at
                admin@gmisummit.com.
              </Typography>
              <Typography gutterBottom variant='body2' color='textPrimary'>
                <Box clone pr={1}>
                  <Typography variant='button' color='textPrimary'>
                    User or you:
                  </Typography>
                </Box>
                Any third party that accesses the Website and is not either (i) employed by GMOL and acting in the
                course of their employment, or (ii) engaged as a consultant or otherwise providing services to GMOL and
                accessing the Website in connection with the provision of such services.
              </Typography>
              <Typography gutterBottom variant='body2' color='textPrimary'>
                <Box clone pr={1}>
                  <Typography variant='button' color='textPrimary'>
                    Website:
                  </Typography>
                </Box>
                The website that you are currently using, https://www.gmisummit.com/, and any sub-domains of this site
                unless expressly excluded by their own terms and conditions.
              </Typography>
            </Box>
          </li>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                In this privacy policy, unless the context requires a different interpretation:
              </Typography>
              <ol type='a'>
                <li>
                  <Typography gutterBottom variant='body2' color='textPrimary'>
                    The singular includes the plural and vice versa;
                  </Typography>
                </li>
                <li>
                  <Typography gutterBottom variant='body2' color='textPrimary'>
                    References to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses, schedules
                    or appendices of this privacy policy;
                  </Typography>
                </li>
                <li>
                  <Typography gutterBottom variant='body2' color='textPrimary'>
                    A reference to a person includes firms, companies, government entities, trusts and partnerships;
                  </Typography>
                </li>
                <li>
                  <Typography gutterBottom variant='body2' color='textPrimary'>
                    “Including” is understood to mean “including without limitation”;
                  </Typography>
                </li>
                <li>
                  <Typography gutterBottom variant='body2' color='textPrimary'>
                    Reference to any statutory provision includes any modification or amendment of it;
                  </Typography>
                </li>
                <li>
                  <Typography gutterBottom variant='body2' color='textPrimary'>
                    The headings and sub-headings do not form part of this privacy policy.
                  </Typography>
                </li>
              </ol>
            </Box>
          </li>
        </ol>
        <Typography gutterBottom variant='h6' className='text-uppercase'>
          <Box my={3}>Data Collected</Box>
        </Typography>
        <Typography variant='body2' color='textPrimary'>
          We may collect, use, store, and transfer different kinds of Data in accordance with the privacy policy, which
          we have grouped together as follows:
        </Typography>
        <ul>
          <li>
            <Typography gutterBottom variant='body2'>
              Identity Data: Includes information related to your name.
            </Typography>
          </li>
          <li>
            <Typography gutterBottom variant='body2'>
              Contact Data: Includes information relating to your email.
            </Typography>
          </li>
        </ul>
        <Typography gutterBottom variant='body2' color='textPrimary'>
          It is important that the Data that we hold about you is accurate and current. Please keep us informed if your
          Data changes during your relationship with us.
        </Typography>
        <Typography gutterBottom variant='body2' color='textPrimary'>
          If you fail to provide Data: Where we need to collect Data by law, or under the terms of a contract we have
          with you, and you fail to provide that Data, we may not be able to perform the contract we have (for example,
          sending you information and updates).
        </Typography>
        <Typography gutterBottom variant='body2' color='textPrimary'>
          You may give us your contact information by filling in the online information form or by corresponding with us
          by post, email, phone, or otherwise. This includes Data you provide when you subscribe to receive regular
          updates, give us feedback, contact us, or request marketing communications.
        </Typography>
        <Typography variant='h6' className='text-uppercase'>
          <Box my={3}>Our use of Data</Box>
        </Typography>
        <ol>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                GMOL is the controller of your Data.
              </Typography>
            </Box>
          </li>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                We will retain any Data you submit for 12 months.
              </Typography>
            </Box>
          </li>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                Unless we are obliged or permitted by law to do so, and subject to any third party disclosures
                specifically set out in this policy, your Data will not be disclosed to third parties. This includes our
                affiliates and / or other companies within our group. Generally, we do not rely on consent as a legal
                basis for processing your Data although we will get your consent before sending third party direct
                marketing communications via email. You have the right to withdraw consent to marketing communications
                at any time by contacting us.
              </Typography>
            </Box>
          </li>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                All Data is stored securely in accordance with requirements of applicable law. For more details on
                security see the clause below (Security).
              </Typography>
            </Box>
          </li>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                Data may be required by us from time to time in order to provide you with the best possible service and
                experience when using our Website. Specifically, Data may be used by us for the following reasons:
              </Typography>
            </Box>
            <ol type='a'>
              <li>
                <Typography variant='body2' color='textPrimary'>
                  Internal record keeping.
                </Typography>
              </li>
              <li>
                <Typography variant='body2' color='textPrimary'>
                  Transmission by email of promotional materials that may be of interest to you.
                </Typography>
              </li>
            </ol>
          </li>
        </ol>
        <Typography gutterBottom variant='h6' className='text-uppercase'>
          <Box my={3}>Third Party Websites and Services</Box>
        </Typography>
        <Typography gutterBottom variant='body2' color='textPrimary'>
          GMOL may, from time to time, employ the services of other parties for dealing with certain processes necessary
          for the operation of the Website. The providers of such services do not have access to certain personal Data
          provided by Users of this Website.
        </Typography>
        <Typography gutterBottom variant='body2' color='textPrimary'>
          This Website may also include links to third-party websites, plug-ins and applications. Clicking on these
          links or enabling those connections may allow third parties to collect or share Data with you. We do not
          control these third-party websites and are not responsible for their privacy statements. When you leave our
          Website, we encourage you to read the privacy policy of every website you visit.
        </Typography>
        <Typography gutterBottom variant='h6' className='text-uppercase'>
          <Box my={3}>Functionality of the Website</Box>
        </Typography>
        <Typography gutterBottom variant='body2' color='textPrimary'>
          To use all features and functions available on the Website, you may be required to submit certain Data.
        </Typography>
        <Typography gutterBottom variant='h6' className='text-uppercase'>
          <Box my={3}>Data Security</Box>
        </Typography>
        <ol>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                Data security is of great importance to GMOL and to protect your Data we have put in place suitable
                physical, electronic and managerial procedures to safeguard and secure Data collected via this Website.
                We will notify you of a breach where we are legally required to do so.
              </Typography>
            </Box>
          </li>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                If password access is required for certain parts of the Website, you are responsible for keeping this
                password confidential.
              </Typography>
            </Box>
          </li>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                We endeavor to do our best to protect your Data. However, transmission of information over the internet
                is not entirely secure and is done at your own risk. We cannot ensure the security of your Data
                transmitted to the Website.
              </Typography>
            </Box>
          </li>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                We limit access of your Data to those employees, agents, contractors, and other third parties who have a
                business need to know such information. They will only process your Data on our instructions and they
                are subject to a duty of confidentiality.
              </Typography>
            </Box>
          </li>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                We will notify you within 72 hours of first having become aware of a breach. We will notify customers
                without undue delay after first becoming aware of a data breach. However, after being informed of a
                potential breach, we may undertake a period of investigation to establish whether or not a breach has
                occurred. During this period of investigation, we may not be regarded as “being aware” of a data breach.
              </Typography>
            </Box>
          </li>
        </ol>
        <Typography gutterBottom variant='h6' className='text-uppercase'>
          <Box my={3}>Your Legal Rights</Box>
        </Typography>
        <Typography gutterBottom variant='body2' color='textPrimary'>
          Under certain circumstances, you have rights under data protection laws in relation to your Data:
        </Typography>

        <List>
          <ListItem>
            <Typography variant='button'>Right to Access</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body2' color='textPrimary'>
              If requested, we will provide a copy of the Data, free of charge, in an electronic format. Communication
              of the Data will be: (i) clear, concise, and easy-to-access, and (ii) written in plain, easy to understand
              language.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='button'>Right to Rectification</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body2' color='textPrimary'>
              You can make a request for rectification verbally or in writing. In certain circumstances, you can refuse
              a request for rectification.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='button'>Right to Erasure</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body2' color='textPrimary'>
              You have the right to ask us to erase your Data, cease further dissemination of the Data, and potentially
              have third parties halt processing of the Data. The conditions for erasure include the Data no longer
              being relevant to the original purposes for processing, or a Data subject withdrawing consent. It should
              also be noted that this right requires us to compare your rights to the public interest in the
              availability of the Data when considering such requests.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='button'>Right to Restriction of Processing</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body2' color='textPrimary'>
              You have the right to request the restriction or suppression of your Data. This is not an absolute right
              and only applies in certain circumstances. When processing is restricted, you are permitted to store the
              Data, but not use it. You can make a request for restriction verbally or in writing. You have one calendar
              month to respond to a request.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='button'>Right to Object to Processing</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body2' color='textPrimary'>
              You have the right to object to the processing of your Data in certain circumstances and an absolute right
              to stop your Data from being used for direct marketing. In other cases, where the right to object applies,
              we may be able to continue processing if we have a compelling reason for doing so. You can make an
              objection verbally or in writing. We will respond to an objection within one calendar month.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='button'>Right to Data Portability</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body2' color='textPrimary'>
              The right to data portability allows you to obtain and reuse your Data for your own purposes across
              different services. It allows you to move, copy or transfer Data from one IT environment to another in a
              safe and secure way, without affecting its usability.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='button'>Right to File a Complaint with Local Data Protection Authority</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body2' color='textPrimary'>
              You have a specific right to lodge a complaint if your Data is processed in a way that does not comply
              with applicable law. We will provide you with the progress and outcome of complaints.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body2' color='textPrimary'>
              In exercising your rights, please note our following policies:
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='button'>No Fee Usually Required</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body2' color='textPrimary'>
              You will not have to pay a fee to access your Data (or to exercise any other legal rights). However, we
              may charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive. Alternatively,
              we can refuse to comply with your request in those circumstances.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='button'>Time to Respond</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body2' color='textPrimary'>
              We try to respond to all legitimate requests within one month. Occasionally, it could take longer than a
              month if your request is complex or you made a number of requests. In this case, we will notify you.
            </Typography>
          </ListItem>
        </List>

        <Typography gutterBottom variant='h6' className='text-uppercase'>
          <Box my={3}>Changes in Business Ownership and Control</Box>
        </Typography>
        <ol>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                Global Manufacturing & Industrialisation Summit may, from time to time, expand or reduce our business
                and this may involve the sale and/or the transfer of control of all or part of Global Manufacturing &
                Industrialisation Summit. Data provided by Users will, where it is relevant to any part of our business
                so transferred, be transferred along with that part and the new owner or newly controlling party will,
                under the terms of this privacy policy, be permitted to use the Data for the purposes for which it was
                originally supplied to us.
              </Typography>
            </Box>
          </li>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                We may also disclose Data to a prospective purchaser of our business or any part of it.
              </Typography>
            </Box>
          </li>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                In the above instances, we will take steps with the aim of ensuring your privacy is protected.
              </Typography>
            </Box>
          </li>
        </ol>
        <Typography gutterBottom variant='h6' className='text-uppercase'>
          <Box my={3}>General</Box>
        </Typography>
        <ol>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                You may not transfer any of your rights under this privacy policy to any other person. We may transfer
                our rights under this privacy policy where we reasonably believe your rights will not be affected.
              </Typography>
            </Box>
          </li>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                If any court or competent authority finds that any provision of this privacy policy (or part of any
                provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent
                required, be deemed to be deleted, and the validity and enforceability of the other provisions of this
                privacy policy will not be affected.
              </Typography>
            </Box>
          </li>
          <li>
            <Box mb={2}>
              <Typography gutterBottom variant='body1'>
                Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be
                deemed a waiver of that, or any other, right or remedy.
              </Typography>
            </Box>
          </li>
        </ol>
        <Typography gutterBottom variant='h6' className='text-uppercase'>
          <Box my={3}>Changes to This Privacy Policy</Box>
        </Typography>
        <Typography gutterBottom variant='body2' color='textPrimary'>
          Global Manufacturing & Industrialisation Summit reserves the right to change this privacy policy as we may
          deem necessary from time to time or as may be required by law. Any changes will be immediately posted on the
          Website and you are deemed to have accepted the terms of the privacy policy on your first use of the Website
          following the alterations.
        </Typography>
        <Typography gutterBottom variant='body2' color='textPrimary'>
          You may contact Global Manufacturing & Industrialisation Summit by email at{' '}
          <Link href='mailto:admin@gmisummit.com'>admin@gmisummit.com</Link>.
        </Typography>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default PrivacyPolicyPage;
